import React, { useCallback, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Card, Button, Collapse } from "reactstrap";
import { useAccount, useBalance } from "wagmi";
// @ts-ignore
import { useRecaptcha } from "react-hook-recaptcha";
import axios from "axios";
import { ethers } from "ethers";
import cogoToast from "cogo-toast";

const containerId = "recaptcha";

const RequestAirdrop: React.FC<{ address?: string }> = ({ address }) => {
  const balance = useBalance({
    address: address as `0x${string}`,
    watch: true,
  });
  const [loading, setIsLoading] = useState(false);
  const [requested, setRequested] = useState(false);

  const { recaptchaLoaded, execute, reset } = useRecaptcha({
    containerId,
    successCallback: (response: string) => requestAirdrop(response, address),
    sitekey: "6LfCqzUjAAAAAIgY-hvNBBm_nKq1_IqAAbD9Z1o-",
    size: "invisible",
    errorCallback: (e: any) => console.error(e),
  });

  const requestAirdrop = async (captcha: string, address?: string) => {
    console.log(address);
    try {
      setIsLoading(true);
      await axios.post(`https://cervejaverso-api.coinsamba.com/airdrop`, {
        token: captcha,
        address: address,
      });
      setRequested(true);
      cogoToast.success(
        "Success, in a few seconds you will have a little MATIC needed to make the mint in your wallet."
      );
    } catch (error) {
      console.error(error, "requestAirdrop");
      cogoToast.error(
        "There was an error with your request, try again or if it persists, contact our team."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const executeCaptcha = useCallback(async () => {
    await reset();
    await execute();
  }, [reset, execute]);

  return (
    <Collapse
      isOpen={
        !requested &&
        balance.data &&
        balance.data.value.eq(ethers.BigNumber.from(0))
      }
    >
      <Card body className="m-3">
        <Button
          color="primary"
          disabled={!recaptchaLoaded || !address || loading}
          onClick={() => executeCaptcha()}
          id={containerId}
        >
          {loading && (
            <>
              <AiOutlineLoading3Quarters className="spin" />{" "}
            </>
          )}
          Request free MATIC
        </Button>
        <small>
          We detected that you don't have enough MATIC to do the mint, request a
          few cents for free.
        </small>
      </Card>
    </Collapse>
  );
};

export default RequestAirdrop;
