import React, { useEffect, useRef } from "react";
import media from "../assets/videos/nft.mp4";

const NFTMedia: React.FC = () => {
  const ref = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (ref.current) {
      ref.current.play();
    }
  }, []);

  return (
    <video
      width="330"
      height="330"
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
      poster=""
      style={{
        borderRadius: 16,
      }}
    >
      <source src={media} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default NFTMedia;
