import { useCallback, useState } from "react";
import { useContract, useSigner } from "wagmi";
import abi from "../static/abis/MintContract.json";
import axios from "axios";

export const useMinter = () => {
  const signer = useSigner();
  const [hash, setHash] = useState();

  const contract = useContract({
    address: "0x4CcD6dD2FFcE855995c7c136a21c50C97d4085DC",
    abi: abi.abi,
    signerOrProvider: signer.data,
  });

  const mint = useCallback(
    async (code: string) => {
      if (contract) {
        const {
          data: { proof },
        } = await axios.get(
          `https://cervejaverso-api.coinsamba.com/?code=${code}`
        );

        if (!proof) {
          throw { reason: "Código inválido" };
        }

        const res = await contract.mint("1", code, proof as string[]);

        setHash(res.hash);
      }
    },
    [contract]
  );

  return { contract, mint, hash };
};
