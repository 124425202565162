import { useConnectModal } from "@rainbow-me/rainbowkit";
import React, { useCallback, useState } from "react";
import { Alert, Button, Card, Form, FormGroup, Input } from "reactstrap";
import { useAccount } from "wagmi";
import NFTMedia from "./components/NFTMedia";
import { useMinter } from "./hooks/useMinter";
import cogoToast from "cogo-toast";
import RequestAirdrop from "./components/RequestAirdrop";

// import { Container } from './styles';

const Mint: React.FC = () => {
  const connect = useConnectModal();
  const account = useAccount();
  const [code, setCode] = useState("");
  const minter = useMinter();
  const [loading, setLoading] = useState(false);

  const handleMint = async () => {
    if (!account.isConnected && connect.openConnectModal) {
      connect.openConnectModal();
    } else {
      try {
        setLoading(true);
        await minter.mint(code);
        // cogoToast.success("Sucesso!");
      } catch (error) {
        // @ts-ignore
        cogoToast.error(error?.reason ?? error?.message ?? error.toString());
      } finally {
        setLoading(false);
      }
    }
  };

  const changeCode = useCallback(
    (text: string) => {
      setCode(text.toUpperCase().trim());
    },
    [setCode]
  );

  return (
    <Card
      style={{
        maxWidth: 500,
        borderRadius: 16,
        borderColor: "transparent",
      }}
      className="my-3 mx-auto shadow"
      // body
    >
      <h1 className="p-3">CervejaVerso NFT</h1>
      <div
        style={{
          // backgroundColor: "#ebebeb",
          display: "flex",
          padding: 10,
        }}
      >
        <div className="mx-auto">
          <NFTMedia />
        </div>
      </div>
      {account.isConnected && <RequestAirdrop address={account.address} />}

      {minter.hash ? (
        <div className="p-3">
          <Alert>
            NFT Mintado com sucesso, confira o hash no explorer:{" "}
            <a
              href={`https://polygonscan.com/tx/${minter.hash}`}
              target="_blank"
              rel="noreferrer"
            >
              {minter.hash}
            </a>
          </Alert>
        </div>
      ) : (
        <Form className="p-3 col">
          <FormGroup className=" ">
            <Input
              placeholder="Insira seu código aqui"
              maxLength={6}
              minLength={6}
              onChange={({ target }) => changeCode(target.value)}
              value={code}
            />
            <small>
              Insira o código que você recebeu ao comprar sua CervejaVerso.
            </small>
          </FormGroup>
          <Button
            color="primary"
            onClick={handleMint}
            block
            disabled={code.length < 6 || loading}
          >
            {!account.isConnected
              ? "Connect Wallet"
              : loading
              ? "Carregando..."
              : "Mint"}
          </Button>
          <small>Para fazer o mint insira seu código.</small>
        </Form>
      )}

      <a
        className="text-center"
        href="https://coinsamba.com"
        target="_blank"
        rel="noreferrer"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        Powered by{" "}
        <img
          src="https://coinsamba.com/_next/image?url=%2Fimg%2Fmain_logo.png&w=256&q=75"
          width={100}
        />
      </a>
    </Card>
  );
};

export default Mint;
